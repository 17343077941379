import React from "react";

const PressItem = ({url, title, image, date, publication}) => {
  return (
    <div class="item card">
          <img
            className="ui avatar image"
            src={`/images/${image}`}
            alt={`${publication} logo`}
          />
          <div class="content">
            <a href={url} class="header">{publication}</a>
            <div class="description">
              <a href={url}>
                {`${title} (${date})`}
              </a>
            </div>
          </div>
        </div>

      );
};

export default PressItem;
