import React from "react";
import { Britta, Eli, Berlin, Trust, OWWB, Sophie, Spies } from '../data/books.js'

const Books = () => {
  return (
    <div>
      <p className="ui centered padded segment header">
        Books by Ronald H. Balson
      </p>
      <div className="ui centered grid">
        <div className="sixteen wide column" style={{ height: "1em" }} id="spies"></div>
        <div className="four wide column">
          <img
            className="ui large image"
            src={Spies.coverURL}
            alt="An Affair of Spies Cover"
          />
        </div>
        <div className="eight wide column">
        <p>
            Nathan Silverman grew up in Berlin in the 1920s, the son of a homemaker and a theoretical physicist. His idyllic childhood was soon marred by increasing levels of bigotry against his family and the rest of the Jewish community, and after his uncle is arrested on Kristallnacht, he leaves Germany for New York City with only his mother’s wedding ring to sell for survival.
          </p>
          <p>While attending an evening course at Columbia in 1942, Nathan notices a recruitment poster on a university wall and decides to enlist in the military and help fight the Nazi regime. To his surprise, he is quickly selected for a special assignment; he is trained as a spy, and ordered to report to the Manhattan Project. There he learns that the Allies are racing to develop a nuclear weapon before the Nazis, and a German theoretical physicist is hoping to defect. The physicist was a friend of his father's, and Nathan's mission is to return to Berlin via France and smuggle him out of Europe.</p>
          <p>Nathan will be accompanied by Dr. Allison Fisher, a brilliant young scientist who can speak French; he travels to her lab at the University of Chicago for a crash course in nuclear physics, then they embark on their adventure. Nathan and Allison soon develop feelings for one another, but as their relationship deepens they move ever closer to their dangerous goal. Will they be able to escape Europe with the defector and start a new life together, or will they fail their mission and become two more casualties of war?</p>
          <p><strong>An Affair of Spies</strong> is an action-packed tale of heroism and love in the face of unspeakable evil. Author Ronald H. Balson has applied his unmatched talent for evocative and painstakingly authentic storytelling to the high-stakes world of espionage and created his most thrilling novel yet.</p>
            
          
          <a
            class="ui button"
            href={Spies.excerpt}
            target="_blank"
            rel="noreferrer"
          >
            Read an excerpt
          </a>
        </div>
      </div>
      <div className="ui centered grid">
        <div className="sixteen wide column" style={{ height: "1em" }} id="britta"></div>
        <div className="four wide column">
          <img
            className="ui large image"
            src="/images/9781250274809_FC-scaled.jpg"
            alt="Defending Britta Stein Cover"
          />
        </div>
        <div className="eight wide column">
          <p>
            <strong>Defending Britta Stein</strong> is a story of bravery,
            betrayal, and redemption—from Ronald H. Balson, the winner of the
            National Jewish Book Award
          </p>
          <p>
            Chicago, 2018: Ole Henryks, a popular restauranteur, is set to be
            honored by the Danish/American Association for his many civic and
            charitable contributions. Frequently appearing on local TV, he is
            well known for his actions in Nazi-occupied Denmark during World War
            II—most consider him a hero.
          </p>
          <p>
            Britta Stein, however, does not. The ninety-year-old Chicago woman
            levels public accusations against Henryks by spray-painting
            “Coward,” “Traitor,” “Collaborator,” and “War Criminal” on the walls
            of his restaurant. Mrs. Stein is ultimately taken into custody and
            charged with criminal defacement of property. She also becomes the
            target of a bitter lawsuit filed by Henryks and his son, accusing
            her of defamation and intentional infliction of emotional distress.
          </p>
          <p>
            Attorney Catherine Lockhart, though hesitant at first, agrees to
            take up Mrs. Stein's defense. With the help of her investigator
            husband, Liam Taggart, Lockhart must reach back into wartime Denmark
            and locate evidence that proves Mrs. Stein's innocence. Defending
            Britta Stein is critically-acclaimed author Ronald H. Balson's
            thrilling take on a modern day courtroom drama, and a masterful
            rendition of Denmark’s wartime heroics.
          </p>
          <a
            class="ui button"
            href={Britta.excerpt}
            target="_blank"
            rel="noreferrer"
          >
            Read an excerpt
          </a>
        </div>
      </div>
      <div className="ui centered grid">
        <div className="sixteen wide column" style={{ height: "1em" }} id="eli"></div>
        <div className="four wide column">
          <img
            className="ui large image"
            src="/images/elis-promise-cover.jpg"
            alt="Eli's Promise Cover"
          />
        </div>
        <div className="eight wide column">
          <p>
            <strong>Eli's Promise</strong> is a masterful work of historical
            fiction spanning three eras—Nazi-occupied Poland, the American Zone
            of post-war Germany, and Chicago at the height of the Vietnam War.
            Award-winning author Ronald H. Balson explores the human cost of
            war, the mixed blessings of survival, and the enduring strength of
            family bonds.
          </p>
          <p>
            1939: Eli Rosen lives with his wife Esther and their young son in
            the Polish town of Lublin, where his family owns a construction
            company. As a consequence of the Nazi occupation, Eli’s company is
            Aryanized, appropriated and transferred to Maximilian Poleski—an
            unprincipled profiteer who peddles favors to Lublin’s subjugated
            residents. An uneasy alliance is formed; Poleski will keep the Rosen
            family safe if Eli will manage the business. Will Poleski honor his
            promise or will their relationship end in betrayal and tragedy?
          </p>
          <p>
            1946: Eli resides with his son in a displaced persons camp in
            Allied-occupied Germany hoping for a visa to America. His wife has
            been missing since the war. One man is sneaking around the camps
            selling illegal visas; might he know what has happened to her?
          </p>
          <p>
            1965: Eli rents a room in Albany Park, Chicago. He is on a mission.
            With patience, cunning, and relentless focus, he navigates
            unfamiliar streets and dangerous political backrooms, searching for
            the truth. Powerful and emotional, Ronald H. Balson's Eli's Promise
            is a rich, rewarding novel of World War II and a husband’s quest for
            justice.
          </p>
          <a
            class="ui button"
            href={Eli.excerpt}
            target="_blank"
            rel="noreferrer"
          >
            Read an excerpt
          </a>
        </div>
      </div>
      <div className="ui centered grid">
        <div className="sixteen wide column" style={{ height: "1em" }}></div>
        <div className="four wide column">
          <img
            className="ui large image"
            src="/images/9781250236203.jpg"
            alt="Karolina's Twins Cover"
          />
        </div>
        <div className="eight wide column">
          <p>
            Lena Woodward, elegant and poised, has lived a comfortable life
            among Chicago Society since she immigrated to the US at the end of
            World War II. But now something has resurfaced that Lena cannot
            ignore: an unfulfilled promise she made long ago that can no longer
            stay buried.
          </p>
          <p>
            Driven to renew the quest that still keeps her awake at night, Lena
            enlists the help of lawyer Catherine Lockhart and private
            investigator Liam Taggart. Behind Lena’s stoic facade are memories
            that will no longer be contained. She begins to recount a tale
            harkening back to her harrowing childhood in Nazi-occupied Poland,
            of the bond she shared with her best friend, the beautiful,
            vivacious Karolina. Lena has cherished the memory of Karolina her
            whole life. But there is something about her own story that is
            unfinished—questions about Karolina and herself that remain
            unanswered. Soon Lena must decide what she is willing to risk to
            uncover a dark secret from the past…and face the ultimate truth.
          </p>
        </div>
      </div>
      <div className="ui centered grid">
        <div className="sixteen wide column" style={{ height: "1em" }}></div>
        <div className="four wide column">
          <img
            className="ui large image"
            src="/images/girl-from-berlin.jpg"
            alt="Girl From Berlin Cover"
          />
        </div>
        <div className="eight wide column">
          <p>
            Ronald H. Balson's <strong>The Girl from Berlin</strong> is the
            winner of the Book Club category for the 2018 National Jewish Book
            Award. In this new novel, Liam and Catherine come to the aid of an
            old friend and are drawn into a property dispute in Tuscany that
            unearths long-buried secrets.
          </p>
          <p>
            An old friend calls Catherine Lockhart and Liam Taggart to his
            famous Italian restaurant to enlist their help. His aunt is being
            evicted from her home in the Tuscan hills by a powerful corporation
            claiming they own the deeds, even though she can produce her own set
            of deeds to her land. Catherine and Liam’s only clue is a bound
            handwritten manuscript, entirely in German, and hidden in its pages
            is a story long-forgotten…
          </p>
          <p>
            Ada Baumgarten was born in Berlin in 1918, at the end of the war.
            The daughter of an accomplished first-chair violinist in the
            prestigious Berlin Philharmonic, and herself a violin prodigy, Ada’s
            life was full of the rich culture of Berlin’s interwar society. She
            formed a deep attachment to her childhood friend Kurt, but they were
            torn apart by the growing unrest as her Jewish family came under
            suspicion. As the tides of history turned, it was her extraordinary
            talent that would carry her through an unraveling society turned to
            war, and make her a target even as it saved her, allowing her to
            move to Bologna—though Italy was not the haven her family had hoped,
            and further heartache awaited
          </p>
          <a
            class="ui button"
            href={Berlin.excerpt}
            target="_blank"
            rel="noreferrer"
          >
            Read an excerpt
          </a>
        </div>
      </div>
      <div className="ui centered grid">
        <div className="sixteen wide column" style={{ height: "1em" }}></div>
        <div className="four wide column">
          <img
            className="ui large image"
            src="/images/9781250127457.jpg"
            alt="The Trust Cover"
          />
        </div>
        <div className="eight wide column">
          <p>
            <strong>The Trust</strong>, from Ronald H. Balson, the international
            bestselling author of <em>Once We Were Brothers</em>, finds private
            investigator Liam Taggart returning to his childhood home for an
            uncle's funeral, only to discover his death might not have been
            natural.
          </p>
          <p>
            When his uncle dies, Liam Taggart reluctantly returns to his
            childhood home in Northern Ireland for the funeral—a home he left
            years ago after a bitter confrontation with his family, never to
            look back. But when he arrives, Liam learns that not only was his
            uncle shot to death, but that he’d anticipated his own murder: In an
            astonishing last will and testament, Uncle Fergus has left his
            entire estate to a secret trust, directing that no distributions be
            made to any person until the killer is found. Did Fergus know, but
            refuse to name, his killer? Was this a crime of revenge, a vendetta
            leftover from Northern Ireland’s bloody sectarian war? After all,
            the Taggarts were deeply involved in the IRA. Or is it possible that
            the killer is a family member seeking Fergus’s estate? Otherwise,
            why postpone distributions to the heirs? Most menacingly, does the
            killer now have his sights on other family members?
          </p>
          <p>
            As his investigation draws Liam farther and farther into the past he
            has abandoned, he realizes he is forced to reopen doors long ago
            shut and locked. Now, accepting the appointment as sole trustee of
            the Fergus Taggart Trust, Liam realizes he has stepped into the
            center of a firestorm.
          </p>
          <a
            class="ui button"
            href={Trust.excerpt}
            target="_blank"
            rel="noreferrer"
          >
            Read an excerpt
          </a>
        </div>
      </div>
      <div className="ui centered grid">
        <div className="sixteen wide column" style={{ height: "1em" }}></div>
        <div className="four wide column">
          <img
            className="ui large image"
            src="/images/9781250065858.jpg"
            alt="Saving Sophie Cover"
          />
        </div>
        <div className="eight wide column">
          <p>
            From Ronald H. Balson, author of <em>Once We Were Brothers</em>,{" "}
            <strong>Saving Sophie</strong> is the powerful story of the lengths
            a father will go through to protect his daughter and an
            action-packed thriller that will take you on an unforgettable
            journey of murder and deception, testing the bonds of family and
            love.
          </p>
          <p>
            Jack Sommers was just an ordinary accountant from Chicago-that is,
            until his wife passed away, his young daughter was kidnapped, and he
            became the main suspect in an $88 million dollar embezzlement case.
            Now Jack is on the run, hoping to avoid the feds long enough to
            rescue his daughter, Sophie, from her maternal grandfather, a
            suspected terrorist in Palestine.
          </p>
          <p>
            With the help of investigative team Liam and Catherine, and a new
            CIA operative, a secret mission is launched to not only rescue
            Sophie but also to thwart a major terrorist attack in Hebron. But
            will being caught in the crossfires of the Palestine-Israeli
            conflict keep their team from accomplishing the task at hand, or can
            they overcome the odds and save countless lives, including their
            own?
          </p>
          <a
            class="ui button"
            href={Sophie.excerpt}
            target="_blank"
            rel="noreferrer"
          >
            Read an excerpt
          </a>
        </div>
      </div>
      <div className="ui centered grid">
        <div className="sixteen wide column" style={{ height: "1em" }}></div>
        <div className="four wide column">
          <img
            className="ui large image"
            src="/images/9781250046390.jpg"
            alt="Once We Were Brothers Cover"
          />
        </div>
        <div className="eight wide column">
          <p>
            Elliot Rosenzweig, a respected civic leader and wealthy
            philanthropist, is attending a fundraiser when he is suddenly
            accosted and accused of being a former Nazi SS officer named Otto
            Piatek, the Butcher of Zamosc. Although the charges are denounced as
            preposterous, his accuser is convinced he is right and engages
            attorney Catherine Lockhart to bring Rosenzweig to justice. Solomon
            persuades attorney Catherine Lockhart to take his case, revealing
            that the true Piatek was abandoned as a child and raised by
            Solomon's own family only to betray them during the Nazi occupation.
            But has Solomon accused the right man?
          </p>
          <p>
            <strong>Once We Were Brothers</strong> is Ronald H. Balson's
            compelling tale of two boys and a family who struggle to survive in
            war-torn Poland, and a young love that struggles to endure the
            unspeakable cruelty of the Holocaust. Two lives, two worlds, and
            sixty years converge in an explosive race to redemption that makes
            for a moving and powerful tale of love, survival, and ultimately the
            triumph of the human spirit.
          </p>
          <a
            class="ui button"
            href={OWWB.excerpt}
            target="_blank"
            rel="noreferrer"
          >
            Read an excerpt
          </a>
        </div>
      </div>
    </div>
  );
};

export default Books;
