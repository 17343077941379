import React from "react";

const Guides = () => {
  return (
    <div>
    <p className="ui centered padded segment header">Reader's Guides</p>
    <div className="ui centered grid">
      <div className="four wide column"><a href="/assets/elis-promise-discussion-guide.pdf" target="_blank">
        <img
            className="ui small centered image"
            src="/images/elis-promise-cover.jpg"
            alt="Eli's Promise Cover"
          />
          <p style={{textAlign:'center'}}>Eli's Promise Reader's Guide</p>
        </a>
      </div>
      <div className="four wide column"><a href="/assets/karolina-discussion-guide.pdf" target="_blank">
      <img
            className="ui small centered image"
            src="/images/9781250236203.jpg"
            alt="Karolina's Twins Cover"
          /><p style={{textAlign:'center'}}>Karolina's Twins Reader's Guide</p>
        </a></div>
      <div className="four wide column"><a href="/assets/saving-sophie-discussion-guide.pdf" target="_blank">
      <img
            className="ui small centered image"
            src="/images/9781250065858.jpg"
            alt="Saving Sophie Cover"
          /><p style={{textAlign:'center'}}>Saving Sophie Reader's Guide</p>
        </a></div>
      <div className="four wide column"><a href="/assets/owwb-discussion-guide.pdf" target="_blank">
      <img
            className="ui small centered image"
            src="/images/9781250046390.jpg"
            alt="Once We Were Brothers Cover"
          /><p style={{textAlign:'center'}}>Once We Were Brothers Reader's Guide</p>
        </a></div>
      </div>
    </div>
  );
};

export default Guides;
