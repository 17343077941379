import React from "react";

const Praise = () => {
  return (
    <div>
        <p className="ui centered padded segment header">
          Praise for Ronald H. Balson</p>
    <div className="ui centered grid">
      <div className="four wide column">
        <img
          className="ui large image"
          src="/images/rhb.jpg"
          alt="Author Ronald Balson"
        />
      </div>
      <div className="eight wide column">
         
          <h3 class="ui header">ONCE WE WERE BROTHERS</h3>

<p>“As compelling a story as you would ever want to read….You won't be disappointed…a new look at an old story, and it will stay with you long after you have finished it.” ―Jackie K Cooper, <em>Huffington Post</em></p>

<p>“<em>Once We Were Brothers</em> tells a great story…gripping…” ―<em>The Chicago Tribune</em></p>

<p>“[OWWB is] richly detailed in character and solidly researched history.” ― <em>The Chicago Tribune</em></p>

<p>“This novel is uplifting and moving, intelligently written and featuring historically accurate context and an unusual insight into human character and motivations. Highly recommended for all readers.” ―<em>Library Journal</em> (starred review)</p>

<p>“Readers will be riveted by this novel’s central question: Will justice long delayed be denied?” ―<em>Kirkus Reviews</em></p>

<p>“This thriller opens with a bang…[a] decades-spanning page-turner.” ―<em>Reader’s Digest</em></p>

<p>“The author describes the atrocities of wartime Poland and the beautiful, eternal romance etween Ben Solomon and his wife, Hannah. Balson's first novel is hard to put down.” ―<em>The Jewish Book World</em></p>

<p>“Balson does a number of things superbly: he crafts a highly readable plotline and makes great use of the Chicago backdrop…many will enjoy this gripping novel for its narrative drive and its emotional storytelling.” ―<em>Booklist</em></p>

<p>“A legal thriller...a reader knows he’s writing from the inside.” ―<em>Chicago Jewish Star</em></p>
<br/>

<p class="ui header">SAVING SOPHIE</p>

<p>“In Balson’s solid sequel to <em>Once We Were Brothers</em>, …[he] succeeds in illuminating the personal side of the Middle Eastern conflict through his deeply human, psychologically credible characters.” ―<em>Publishers Weekly</em></p>

<p>“<em>Saving Sophie</em> is a page turning read filled with despair and anger but with hope, love, and humanity at its core.” ―<em>Jewish Book Council</em></p>

<p>"Balson keeps readers wanting to know more, as he ties up each loose end toward the conclusion of this action-adventure thriller.” ―<em>Baltimore Jewish News</em></p>

<p class="ui header">KAROLINA’S TWINS</p>
<p>Readers who crave more books like Balson’s <em>Once We Were Brothers</em> and Kristin Hannah’s bestselling <em>The Nightingale</em> will be enthralled by <em>Karolina’s Twins</em>.” —<em>Booklist</em> (starred review)</p>
<p>"A heart-wrenching but ultimately triumphant story." —<em>Chicago Tribune</em></p>
<p>“A heartbreaking tale of a mother’s love, friendship, and family in the face of increasingly brutal conditions and the constant threat of imminent death in Nazi-occupied Poland…reminiscent of the author’s first novel <em>Once We Were Brothers</em>…compelling.” ―<em>Library Journal</em></p>

<p>“The third in Balson’s promising series about a husband-and-wife investigation team specializing in Holocaust cases. [Lena Woodward’s] survivor account becomes the main source of suspense, since she is reluctant to reveal the full horror of what she experienced until the end…and [it] does not disappoint.” ―<em>Kirkus</em></p>

<p>“<em>Karolina’s Twins</em> is involving right from the start, it reads quickly, and fans of historical fiction are sure to enjoy it.” ― <em>Chicago Jewish Star</em></p>

<p class="ui header">THE TRUST</p>

<p>“A thrilling, action-filled suspense novel.... Those who loved Balson’s other books like <em>Once We Were Brothers</em> and <em>Karolina’s Twins</em> will love this latest entry.” —<em>The Huffington Post</em></p>

<p>“Weaving together history with mystery, Ronald H. Balson crafts a compelling tale.” ―<em>Us Weekly</em></p>

<p>“This top-notch thriller will keep readers riveted to the very last page. The Northern Irish setting with appeal to fans to Adrian McKinty and Stewart Nevill, and the familial and political issues will attract psychological thriller addicts.” ―<em>Library Journal</em></p>
<p>“[<em>The Trust</em>] did not disappoint.” ―<em>Beyondthebookends</em></p>

<p class="ui header">THE GIRL FROM BERLIN</p>

<p>“Ron Balson never disappoints with his rich, historical thrillers. Woven with research and detail, <em>The Girl from Berlin</em> is not to be missed.” ―Alyson Richman, international bestselling author of <em>The Lost Wife</em></p>

<p>“Ron Balson’s masterful historical novel <em>The Girl from Berlin</em> illustrates how crimes buried in the past can reverberate across future generations. In this story, the powerhouse duo of attorney Catherine Lockhart and investigator Liam Taggart must travel to Italy to solve a mystery that somehow ties an elderly women in Tuscany who is about to lose her beloved vineyard, with a Jewish violin prodigy in 1930’s Berlin during Hitler’s rise. This is a fascinating, fast-paced dual-narrative that I could not put down. It is a heart-wrenching story of survival, hope and, ultimately, redemption that is sure to thrill current fans of Balson’s novels and create many new ones!” ―Jane Healey, author of <em>The Saturday Evening Girls Club</em></p>

<p>“Balson’s many fans will thoroughly enjoy this new addition to the series, which continues the earlier novels’ dynamics plotting, compelling characters, and back-and-forth between-eras action. Newcomers will find the portrayals of the plight of the Jews in Central Europe leading up to and during World War II an unvarnished testament to the ugly truth.” ―<em>Library Journal</em></p>

<p>"Murder, deception, and greed are involved, but this compelling story also offers the beauty of music and love, and the possibility of redemption."- <em>Jewish Book Council</em></p>


<p class="ui header">ELI’S PROMISE</p>

<p>"National Jewish Book Award winter Ron Balson returns triumphantly with <em>Eli’s Promise</em>, a captivating saga of the Holocaust and its aftermath spanning decades and continents. Readers will not be able to put this book down, but will turn the pages compulsively with heart in throat, eager to learn the fate of the Rosen family. Balson’s meticulous historical detail, vivid prose and unforgettable characters further solidify his place among the most esteemed writers of historical fiction today."
―Pam Jenoff, <em>New York Times</em> Bestselling Author of <em>The Lost Girls of Paris</em></p>

<p>"Ron Balson’s latest historical novel <em>Eli's Promise</em> is a powerful, superbly crafted tale. With meticulous historical detail, the novel is equal parts heartbreaking and life affirming, with themes that are as relevant today as ever. <em>Eli's Promise</em> is historical fiction at its finest, a riveting page turner that will hook readers from the start."
―Jane Healey, Bestselling Author of <em>The Beantown Girls</em></p>



<p>"Ronald Balson skillfully weaves the novel’s plot over twenty years while maintaining an engaging pace with a series of seamless flashbacks. Furthermore, he integrates the complexities and the scope of the systematic dehumanization of Jews before, during, and after World War II."
―Melissa Warren, <em>Historical Novel Society</em></p>

<p>"Superb…<em>Eli’s Promise</em> is a moving and suspenseful work of authoritative historical fiction. It is profoundly informative, entirely compelling and highly recommended."
―Jack Kramer, <em>BookReporter</em></p>

<p>"Balson pulls no punches...His story is a five star version of a period of history that proved a horror for those living in it."
―Stacy Alesi, <em>BookBitch</em></p>

<p>"Balson juggles between his three stories effectively, writing with great emotion but without overt melodrama, always aware of the tragic ways in which history repeats itself."
―Bill Ott, <em>Booklist</em></p>
      </div>
    </div>
</div>
  );
};

export default Praise;
