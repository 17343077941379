import React, {useState} from "react";
// import { Link } from "react-router-dom";
import Book from './Book.js'
import BookDetail from './BookDetail.js'
import Button from './Button.js'
import { Britta, Eli, Karolina, Berlin, Trust, OWWB, Sophie, Spies } from '../data/books.js'

const Home = () => {

  const [activeBook, setActiveBook] = useState(null)

  console.log(activeBook)
  
  return (
  <>
      <div className="is-mobile">
         <img className="ui image" src="/images/spies-promo.jpg" alt="affair of spies promo"/>
        <div className="ui stackable grid" style={{justifyContent: "space-around", height:"2rem", marginBottom:"6rem"}}>
          <a className="ui primary button" href={Spies.excerpt} target="_blank" rel="noreferrer" style={{width:"90%", height:"40px", marginBottom: "1rem", marginTop: "2rem"}}> Read the First Two Chapters</a>
          <a className="ui primary button" href={Spies.local} target="_blank" rel="noreferrer" style={{width:"90%", height:"40px", marginBottom: "1rem"}}>Buy Local</a>
          <a className="ui primary button" href={Spies.bn} target="_blank" rel="noreferrer" style={{width:"90%", height:"40px", marginBottom: "1rem"}}>Barnes &amp; Noble</a>
          <a className="ui primary button" href={Spies.amazon} target="_blank" rel="noreferrer" style={{width:"90%", height:"40px", marginBottom: "1rem"}}>Amazon</a>
        </div>
        <div style={{height:"200px", background: "rgba(0,0,0,0)"}}></div>
      </div>
      <div className="is-desktop">
         <img className="ui image" src="/images/spies-promo.jpg" alt="affair of spies promo"  style={{width:"100%"}}  />
        <div className="ui grid" style={{position:"relative", top:"-55px", justifyContent: "space-around", height:"2rem", marginBottom:"6rem"}}>
          <a className="ui primary button" href={Spies.excerpt} target="_blank" rel="noreferrer" style={{width:"200px", height:"36px"}}>Read First Two Chapters</a>
          <Button type="local" link={Spies.local} />
          <Button type="bn" link={Spies.bn} />
          <Button type="amazon" link={Spies.amazon} />
        </div>
      </div>
      {/* <div className="is-mobile">
         <img className="ui image" src="/images/DefendingBrittaStein_promo_mobile.png" alt="defending britta stein promo"/>
        <div className="ui stackable grid" style={{justifyContent: "space-around", height:"2rem", marginBottom:"6rem"}}>
          <a className="ui primary button" href="https://d827xgdhgqbnd.cloudfront.net/wp-content/uploads/2021/08/25141012/DEFENDING_BRITTA_STEIN_2chapter_excerpt.pdf" target="_blank" rel="noreferrer" style={{width:"90%", height:"40px", marginBottom: "1rem", marginTop: "2rem"}}> Read the First Two Chapters</a>
          <a className="ui primary button" href="https://www.indiebound.org/book/9781250274809" target="_blank" rel="noreferrer" style={{width:"90%", height:"40px", marginBottom: "1rem"}}>Buy Local</a>
          <a className="ui primary button" href="https://www.barnesandnoble.com/w/defending-britta-stein-ronald-h-balson/1138461964?ean=9781250274809" target="_blank" rel="noreferrer" style={{width:"90%", height:"40px", marginBottom: "1rem"}}>Barnes &amp; Noble</a>
          <a className="ui primary button" href={Britta.amazon} target="_blank" rel="noreferrer" style={{width:"90%", height:"40px", marginBottom: "1rem"}}>Amazon</a>
        </div>
      </div>
      <div className="is-desktop">
         <img className="ui image" src="/images/DefendingBrittaStein_FacebookCover_V1.png" alt="defending britta stein promo"  style={{width:"100%"}}  />
        <div className="ui grid" style={{position:"relative", top:"-55px", justifyContent: "space-around", height:"2rem", marginBottom:"6rem"}}>
          <a className="ui primary button" href={Britta.excerpt} target="_blank" rel="noreferrer" style={{width:"200px", height:"36px"}}>Read First Two Chapters</a>
          <Button type="local" link={Britta.local} />
          <Button type="bn" link={Britta.bn} />
          <Button type="amazon" link={Britta.amazon} />
        </div>
      </div>
        <div style={{height:"200px", background: "rgba(0,0,0,0)"}}></div>
    <div className="is-mobile" style={{background: "AliceBlue"}}>
      <img
            className="ui image"
            src="/images/ElisPromise_promo_mobile.png"
            alt="Eli's Promise promo"
            />
          <div className="ui stackable grid" style={{justifyContent: "space-around", marginBottom:"6rem"}}>
          <a className="ui primary button" href={Eli.excerpt}  target="_blank" rel="noreferrer" style={{width:"90%", height:"40px", marginBottom: "1rem", marginTop: "2rem"}}>Read an Excerpt</a>
          <a className="ui primary button" href={Eli.local}  target="_blank" rel="noreferrer" style={{width:"90%", height:"40px", marginBottom: "1rem"}}>Buy Local</a>
          <a className="ui primary button" href={Eli.bn}  target="_blank" rel="noreferrer" style={{width:"90%", height:"40px", marginBottom: "1rem"}}>Barnes &amp; Noble</a>
          <a className="ui primary button" href={Eli.amazon}  target="_blank" rel="noreferrer" style={{width:"90%", height:"40px", marginBottom: "1rem"}}> Amazon</a>
          </div>
        <hr style={{width: "100%", border: "6px solid black", margin:"1rem 0"}}/>
    </div>
    <div className="is-desktop ui" style={{background: "AliceBlue", padding: "3rem 0"}}>
      <img
            className="ui image"
            src="/images/ElisPromise_FacebookCover_AvailableNow.png"
            alt="Eli's Promise promo"
            style={{width:"100%"}} 
            />
          <div>
          <div className="ui centered grid" style={{position:"relative", top:"-25px", justifyContent: "space-around"}}>
          <Button type="excerpt" link={Eli.excerpt} />
          <Button type="local" link={Eli.local} />
          <Button type="bn" link={Eli.bn} />
          <Button type="amazon" link={Eli.amazon} />
          </div>
        {/* <hr style={{width: "100%", border: "1px solid black", margin:"1rem 0"}}/> 
          
            </div>
    </div> */}
  <h1 className="ui centered header" style={{padding: "6rem 0"}}>Books</h1>
    {activeBook && <BookDetail book={activeBook} /> }
  <div className="ui centered padded grid" style={{margin: '1em', justifyContent: 'space-around'}}>
    <Book book={Spies} active={activeBook} onClick={() => setActiveBook(activeBook===Spies ? null : Spies)}/>
    <Book book={Britta} active={activeBook} onClick={() => setActiveBook(activeBook===Britta ? null : Britta)}/>
    <Book book={Eli} active={activeBook} onClick={() => setActiveBook(activeBook===Eli ? null : Eli)}/>
    <Book book={Berlin} active={activeBook} onClick={() => setActiveBook(activeBook===Berlin ? null : Berlin)}/>
    <Book book={Trust} active={activeBook} onClick={() => setActiveBook(activeBook===Trust ? null : Trust)}/>
    <Book book={Karolina} active={activeBook} onClick={() => setActiveBook(activeBook===Karolina ? null : Karolina)}/>
    <Book book={Sophie} active={activeBook} onClick={() => setActiveBook(activeBook===Sophie ? null : Sophie)}/>
    <Book book={OWWB} active={activeBook} onClick={() => setActiveBook(activeBook===OWWB ? null : OWWB)}/>
    
  </div>
  </>
  )
};

export default Home;
