import "./App.css";
import Home from "./components/Home.js";
import About from "./components/About.js";
import Books from "./components/Books.js";
import Praise from "./components/Praise.js";
import Press from "./components/Press.js";
import Guides from "./components/Guides.js";
import Events from "./components/Events.js";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";

function App() {
  
    
  return (
    <Router>
        <img src="/images/banner2.png" className="ui image" alt="Ronald H. Balson, Best Selling Author" />
        <div className="ui inverted four item menu">
          <NavLink
            className="item"
            activeClassName="active"
            exact={true}
            to="/"
          >
            Home
          </NavLink>
          <NavLink className="item" activeClassName="active" to="/about">
            About
          </NavLink>
          <NavLink className="item" activeClassName="active" to="/books">
            Books
          </NavLink>
          <NavLink className="item" activeClassName="active" to="/events">
            Events
          </NavLink>
        </div>



        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/books">
            <Books />
          </Route>
          <Route path="/press">
            <Press />
          </Route>
          <Route path="/praise">
            <Praise />
          </Route>
          <Route path="/guides">
            <Guides />
          </Route>
          <Route path="/events">
            <Events />
          </Route>
        </Switch>
        <hr />
      <div className="ui inverted menu">
        <NavLink className="item" activeClassName="active" to="/press">
          Press
        </NavLink>
        <NavLink className="item" activeClassName="active" to="/guides">
          Reader's Guides
        </NavLink>
        <NavLink className="item" activeClassName="active" to="/praise">
          Praise
        </NavLink>
        <a
          className="right item"
          href="https://www.facebook.com/RonaldBalson/"
          target="_blank"
          rel="noreferrer"
          >
          <i className="facebook icon"></i>
        </a>
        <a
          className="item"
          href="https://twitter.com/ronbalson"
          target="_blank"
          rel="noreferrer"
          >
          <i className="twitter icon"></i>
        </a>
        <a
          className="item"
          href="https://www.goodreads.com/ronbalson"
          target="_blank"
          rel="noreferrer"
          >
          <i className="goodreads icon"></i>
        </a>
        <a
          className="item"
          href="mailto:contact@ronaldbalson.com"
          target="_blank"
          rel="noreferrer"
          >
          <i className="envelope icon"></i>
        </a>
      </div>

    </Router>
  );
}
export default App;
