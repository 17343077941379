import React from "react";

const Events = () => {
  
  return (
    <div>
      <p className="ui centered padded segment header">Events</p>
      <div style={{position: "relative", height: 0, width: "90%", paddingBottom: "50%", left:"5%"}}>
    <iframe src="https://calendar.google.com/calendar/embed?src=c_psnfmdbblsrli3rsro44d5vk2o%40group.calendar.google.com&ctz=America%2FChicago&showPrint=0&showTabs=1&showCalendars=0&showTitle=0&showDate=0" style={{border: "0", position: "absolute", top: 0, left: 0,  width: "100%", height: "100%"}} width="800" height="600" frameborder="0" scrolling="no" title="Events Calendar"></iframe>
  </div>
    </div>
  );
};

export default Events;
