import React from 'react';

const BookDetail = obj => {
    console.log(obj.book)
    return (
        <div className="ui segment" style={{margin:'1em 0', minHeight: '320px'}}>            
        <h1 className="ui centered header">{obj.book.title}</h1>
        <img className="ui small image" src={obj.book.coverURL} alt={obj.book.title} style={{float: "left", marginRight: '1em'}}/>
            <div dangerouslySetInnerHTML={{__html: obj.book.description}} />
        <div className="ui centered grid" style={{margin:"1em"}}>
            {obj.book.excerpt && <a href={obj.book.excerpt} target="_blank" rel="noreferrer"><div className="detail ui primary button">Read an excerpt</div></a> }
            {obj.book.goodreads && <a href={obj.book.goodreads} target="_blank" rel="noreferrer"><div className="detail ui primary button"><i className="goodreads g icon" /></div></a> }
            {obj.book.local && <a href={obj.book.local} target="_blank" rel="noreferrer"><div className="detail ui primary button">Buy Local</div></a> }
            {obj.book.bn && <a href={obj.book.bn} target="_blank" rel="noreferrer"><div className="detail ui primary button">Barnes &amp; Noble</div></a> }
            {obj.book.amazon && <a href={obj.book.amazon} target="_blank" rel="noreferrer"><div className="detail ui primary button"><i className="amazon icon" /> </div></a> }
            
        </div>
        </div>
    ) 
}

export default BookDetail;