import React from 'react';

const Book = ({book, onClick, active}) => {
    
    return (
        <div className="ui" style={{margin:'1em .5em'}} onClick={onClick}>
            <img className="ui small image glows" src={book.coverURL} alt={book.title} style={ active===book ? {boxShadow: "0 0 10px 10px white"} : {boxShadow: 'none'}}/>
        </div>
    ) 
}

export default Book;