import React from "react";
import PressItem from './PressItem.js'

const Press = () => {
  return (
    <div>
     <p className="ui centered padded segment header">Press</p>
      <div class="press-cards ui cards">
        <PressItem 
          image="newsweek-logo.png" 
          url="https://www.newsweek.com/2021/09/03/20-new-books-cozy-this-fall-1622707.html" 
          publication="Newsweek" 
          date="9/3/21" 
          title="20 New Books to Cozy up With This Fall"
          />
        <PressItem 
          image="NYT.jpg" 
          url="https://www.nytimes.com/2020/10/13/books/review/new-this-week.html" 
          publication="New York Times" 
          date="10/13/20" 
          title="New and Noteworthy Audiobooks"
          />
        <PressItem 
          image="ajt.jpg" 
          url="https://www.atlantajewishtimes.com/the-girl-from-berlin-a-treasure-hunt-through-history/" 
          publication="Atlanta Jewish Times" 
          date="10/26/18" 
          title="The Girl from Berlin: A Treasure Hunt Through History"
          />
        <PressItem 
          image="trib.png" 
          url="https://www.chicagotribune.com/entertainment/ct-ron-balson-kogan-sidewalks-ae-1009-20161006-column.html" 
          publication="Chicago Tribune" 
          date="10/06/16" 
          title="Novelist Ronald Balson makes the argument for second careers"
          />
        <PressItem 
          image="ca-cover.jpg" 
          url="https://www.cigaraficionado.com/article/the-lawyer-who-wrote-history-18780" 
          publication="Cigar Aficionado" 
          date="May/June 2016" 
          title="The Lawyer Who Wrote History"
          />
        <PressItem 
          image="oc_register.png" 
          url="https://www.ocregister.com/2013/10/23/five-questions-with-ronald-h-balson/" 
          publication="Orange County Register" 
          date="10/23/13" 
          title="Five questions with Ronald H. Balson"
          />
       
          </div>

    </div>
  );
};

export default Press;
