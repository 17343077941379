import React from "react";

const About = () => {
  return (
    <div>
      <p className="ui centered padded segment header">About Ronald H. Balson</p>
    <div className="ui centered grid">
    <div className="four wide column">
      <img className="ui large image" src="/images/rhb.jpg" alt="Author Ronald Balson" />
    </div>
    <div className="eight wide column">
    <p><strong>Ronald H. Balson</strong> is the author of <em>Once We Were Brothers</em>, <em>Saving Sophie</em>, <em>Karolina&#39;s
Twins</em>, <em>The Trust</em>, <em>The Girl From Berlin</em>, <em>Eli’s Promise</em>, <em>Defending
Britta Stein</em>, and his most recent novel, <em>An Affair of Spies</em>.</p>
<p>
  During the early 2000s, a complex telecommunications lawsuit took Ron to Warsaw, Zamosc, Nowy Sacz and other Polish cities. While working in Poland, Ron was profoundly affected by the scars and memorials of World War II which inspired him to write <em>Once We Were Brothers</em>, his first novel and an international best seller.</p> <p>Extensive travels to Israel and the Middle East provided the inspiration for <em>Saving Sophie</em>, his second novel. While on his <em>Once We Were Brothers</em> book tour, Ron met Fay Waldman, a survivor of multiple Nazi prison camps. Her remarkable story inspired and provided the backdrop for Ron's third novel and international bestseller, <em>Karolina's Twins</em>. The “Troubles,” the thirty-year dispute between the Catholics and the Protestants in Northern Ireland formed the backdrop for Ron’s fourth book, a murder mystery entitled, <em>The Trust</em>. Research in Berlin and Tuscany resulted in the haunting and award-winning <em>The Girl From Berlin</em>. </p>
  <p>In <em>Eli’s Promise</em>, Ron returned to wartime Poland and specifically the historic city of Lublin. The novel spans twenty years, from Nazi-occupied Poland to a displaced person’s camp in the American Zone of Allied-occupied Germany, and then to an ethnic neighborhood in Chicago at the height of the Vietnam War.  <em>Defending Britta Stein</em> is an exciting courtroom drama recalling how the Danish people, exercising extraordinary bravery, came together as one to save their Jewish citizens during the Nazi occupation. </p>
  <p><em>An Affair of Spies</em>, Ron’s most recent historical novel, takes place during a one-month period in 1943, at a time when the U.S. and Germany were locked in a race to develop the first nuclear weapon.  In <em>An Affair of Spies</em>, Nathan Silverman, a young American soldier, is paired with Allison Fisher, a nuclear physicist, and smuggled into Nazi Berlin to rescue a defecting scientist, secretly assess the development of Germany’s nuclear program, and provide insight on how it may be sabotaged. The novel draws heavily on the parallels between the American and German scientific advances, and the moral implications of creating such a weapon.
  </p> 
  <p>Ron has been the recipient of several honors. <em>The Girl From Berlin</em> was awarded the 2018 National Jewish Book Award for fiction. It was also a 2019 adult fiction selection of the Illinois Reading Council’s statewide program, Illinois Reads. <em>Eli’s Promise</em> was selected by the New York Times as New &amp; Noteworthy and was a Target Book Club Selection. <em>Defending Britta Stein</em> was a Newsweek Choice.  Ron was a finalist for the Harper Lee Award for Legal Fiction, and a runner up in the Italian Premio Selezione Bancarella Award for Italian Literature, both for <em>Once We Were Brothers</em>. Ron’s books have been best sellers in Poland, Italy, Hungary, Czechoslovakia, and South Africa.  Ron has appeared on numerous television and radio shows and has been a featured lecturer nationally and internationally on his writing.</p>

    </div>
    </div>
</div>
  );
};

export default About;
