import React from 'react';

const Button = ({type, link}) => {
    let text
    switch(String(type)) {
        case "excerpt":
            text = "Read an Excerpt"
            break
            
        case "amazon":
            text = "Amazon"
            break
        case "bn":
            text = "Barnes & Noble"
            break
        case "local":
            text = "Buy Local"
            break
        default:
            break
    }
    return (
            <a className="ui primary button" href={link} target="_blank" rel="noreferrer" style={{width:"200px", height:"36px"}}>{text}</a>
    ) 
}

export default Button;